/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './components/theme';
import GlobalStyles from './components/theme/global-styles';

const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {element}
  </ThemeProvider>
);

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default wrapPageElement;
