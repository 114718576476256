const variants = {
  largeTitle: {
    fontSize: '3.6rem',
    fontWeight: '500',
    lineHeight: '4.5rem',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: '500',
    lineHeight: '3.3rem',
  },
  header1: {
    fontSize: '2rem',
    fontWeight: '400',
    lineHeight: '2.8rem',
  },
  header1Med: {
    fontSize: '2rem',
    fontWeight: '500',
    lineHeight: '2.8rem',
  },
  header2: {
    fontSize: '1.8rem',
    fontWeight: '400',
    lineHeight: '2.4rem',
  },
  header2Med: {
    fontSize: '1.8rem',
    fontWeight: '500',
    lineHeight: '2.5rem',
  },
  header3: {
    fontSize: '1.6rem',
    fontWeight: '400',
    lineHeight: '2.2rem',
  },
  header3Med: {
    fontSize: '1.6rem',
    fontWeight: '500',
    lineHeight: '2.2rem',
  },
  header4: {
    fontSize: '1.4rem',
    fontWeight: '400',
    lineHeight: '2rem',
  },
  header4Med: {
    fontSize: '1.4rem',
    fontWeight: '500',
    lineHeight: '2rem',
  },
  body1: {
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '2.2rem',
  },
  body2: {
    fontSize: '1.3rem',
    fontWeight: '400',
    lineHeight: '2em',
  },
  caption1: {
    fontSize: '1.1rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
  },
};

export default variants;
