import { createGlobalStyle } from 'styled-components';

import modernNormalize from './modern-normalize';

const GlobalStyles = createGlobalStyle`
  ${modernNormalize}

  html {
    font-size: 62.5%;
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    font-family: ${(props) => props.theme.fontFamilies};
    font-weight: 400;
    color: ${(props) => props.theme.colours.darkGrey};
    background-color: ${(props) => props.theme.colours.veryLightGrey};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
  }

  body:not(.user-is-tabbing) *:focus {
    outline: none;
  } 
`;

export default GlobalStyles;
