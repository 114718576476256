import { css } from 'styled-components';
import variants from './theme-variants';

export const colours = {
  blue: '#22BBE0',
  blueHover: '#15A7CB',
  accentBlue: '#3EA6F1',
  indigo: '#19469D',
  yellow: '#FFE03F',
  black: '#000000',
  darkerGrey: '#222222',
  darkGrey: '#4F4F4F',
  mediumGrey: '#828282',
  borderGrey: '#C7C7CD',
  lightGrey: '#E6E9ED',
  veryLightGrey: '#F7F9FC',
  white: '#ffffff',
  white20: 'rgba(255, 255, 255, 0.2)',
  white80: 'rgba(255, 255, 255, 0.8)',
};

export const rgba = (colour, alpha) => {
  const colourValues = colour.substring(1);
  const red = parseInt(colourValues.substring(0, 2), 16);
  const green = parseInt(colourValues.substring(2, 4), 16);
  const blue = parseInt(colourValues.substring(4, 6), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

const fontFamilies = '"Inter", Helvetica, Arial, sans-serif';

const weights = {
  light: '300',
  regular: '400',
  bold: '700',
};

const brakepoints = {
  tablet: 1024,
  mobile: 768,
  small: 576,
  tabletMax: 'max-width: 1024px',
  mobileMax: 'max-width: 768px',
  smallMax: 'max-width: 576px',
};

const breakpoints = ['576px', '768px', '1024px', '1200px'];
// eslint-disable-next-line prefer-destructuring
breakpoints.sm = breakpoints[0];
// eslint-disable-next-line prefer-destructuring
breakpoints.md = breakpoints[1];
// eslint-disable-next-line prefer-destructuring
breakpoints.lg = breakpoints[2];
// eslint-disable-next-line prefer-destructuring
breakpoints.xl = breakpoints[3];
// custom jumbotron header breakpoint
breakpoints.home = '960px';
breakpoints.header = '840px';

const radii = {
  small: '3px',
  large: '6px',
};

export const mq = Object.keys(brakepoints).reduce((accumulator, label) => {
  const prefix = typeof brakepoints[label] === 'string' ? '' : 'min-width:';
  const suffix = typeof brakepoints[label] === 'string' ? '' : 'px';
  accumulator[label] = (cls) => css`
    @media (${prefix + brakepoints[label] + suffix}) {
      ${cls};
    }
  `;
  return accumulator;
}, {});

const colorStyles = {
  whiteHover: {
    color: rgba(colours.white, 0.8),
    '&:hover': {
      color: colours.white,
    },
    '&:active': {
      color: colours.white,
    },
  },
  primary: {
    backgroundColor: colours.darkBlue,
    color: colours.white,
    '&:hover': {
      backgroundColor: colours.blue,
    },
  },
  inverted: {
    backgroundColor: colours.white,
    color: colours.darkBlue,
    '&:hover': {
      backgroundColor: colours.blue,
    },
  },
  secondary: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: colours.white,
    border: `1px solid ${colours.white}`,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
};

export default {
  brakepoints,
  breakpoints,
  colours,
  colors: colours,
  colorStyles,
  fontFamilies,
  mq,
  radii,
  rgba,
  weights,
  fontWeights: weights,
  variants,
};
